import React from 'react';
import about from '../assets/img/about.jpg';
import '../components/about/about.css'

const AboutSection = () => {
  return (
    <div className="container-xxl py-5" id="about-us">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: '400px' }}>
            <div className="position-relative h-100">
              <img className="img-fluid position-absolute w-100 h-100" src={about} alt="" style={{ objectFit: 'cover' }} />
            </div>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
            <h3>About Us</h3>
            <h1 className="mb-4">Welcome to Autopy</h1>
            <p className="auto__about-content">AutoPy offers comprehensive programs covering DevOps, Agile/Scrum, GitHub, Co-Pilot, ChatGPT, architecture, design patterns, and TDD. Participants gain expertise in streamlining software development processes, fostering collaboration, and driving efficiency. They learn agile methodologies and the Scrum framework for faster and adaptive product delivery. Our training also covers GitHub for version control and collaboration. Additionally, participants explore AI capabilities with Co-Pilot and ChatGPT. They acquire skills in architecture design, utilizing modular and scalable systems, and design patterns for reusable solutions. Lastly, our TDD training promotes a test-first approach, ensuring code correctness and maintainability. Our training empowers professionals to thrive in the dynamic tech landscape and drive innovation.</p>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
