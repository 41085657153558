import React from 'react';
import course1Image from '../assets/img/course-1.jpg';
import course2Image from '../assets/img/course-2.jpg';
import course3Image from '../assets/img/course-3.jpg';


const FooterSection = () => {
  return (
    <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s" id='contact-us'>
      <div className="container py-5">
        <div className="row g-5">
          {/* Quick Links */}
          <div className="col-lg-4 col-md-6">
            <h4 className="text-white mb-3">Quick Link</h4>
            <a className="btn btn-link" href="#about-us">About Us</a>
            <a className="btn btn-link" href="#contact">Contact Us</a>
            <a className="btn btn-link" href="">Privacy Policy</a>
            <a className="btn btn-link" href="">Terms & Condition</a>
            <a className="btn btn-link" href="">FAQs & Help</a>
          </div>
          {/* Contact */}
          <div className="col-lg-4 col-md-6">
            <h4 className="text-white mb-3">Contact</h4>
            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Manish Blitz, Hadapsar, Pune:411013</p>
            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+8099795212</p>
            <p className="mb-2"><i className="fa fa-envelope me-3"></i>info@autopy.in</p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a>
              <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-facebook-f"></i></a>
              <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-youtube"></i></a>
              <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>
          {/* Gallery */}
          <div className="col-lg-4 col-md-6">
            <h4 className="text-white mb-3">Gallery</h4>
            <div className="row g-2 pt-2">
            <div className="col-4">
              <img className="img-fluid bg-light p-1" src={course1Image} alt="" />
            </div>
            <div className="col-4">
              <img className="img-fluid bg-light p-1" src={course2Image} alt="" />
            </div>
            <div className="col-4">
              <img className="img-fluid bg-light p-1" src={course3Image} alt="" />
            </div>
            <div className="col-4">
              <img className="img-fluid bg-light p-1" src={course2Image} alt="" />
            </div>
            <div className="col-4">
              <img className="img-fluid bg-light p-1" src={course3Image} alt="" />
            </div>
            <div className="col-4">
              <img className="img-fluid bg-light p-1" src={course1Image} alt="" />
            </div>
          </div>
          </div>
       
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a className="border-bottom" href="#">www.autopy.in</a>, All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <a href="">Home</a>
                <a href="">Cookies</a>
                <a href="">Help</a>
                <a href="">FAQs</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
