import React, {useEffect} from 'react';
// import Navbar from './bootstrap/Navbar';
// import Carousel from './bootstrap/Carousel';
import ServiceSection from './bootstrap/ServiceSection';
import AboutSection from './bootstrap/AboutSection';
import CategoriesSection from './bootstrap/CategoriesSection';
import CoursesSection from './bootstrap/CoursesSection';
import TeamSection from './bootstrap/TeamSection';
// import TestimonialSection from './bootstrap/TestimonialSection';
import FooterSection from './bootstrap/FooterSection';
import BackToTopButton from './bootstrap/BackToTopButton';
import Spinner from './bootstrap/Spinner';
import './assets/lib/animate/animate.min.css';
import './assets/lib/owlcarousel/assets/owl.carousel.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/stylelms.css';
import WOW from 'wowjs'; // Import the wowjs library
import './'
import Navbar from './components/navbar/Navbar';
import { Header, Services } from './containers';

const App = () => {
  useEffect(() => {
    // Initialize the WOW.js library when the component mounts
    new WOW.WOW().init();
  }, []);

  return (
    <div style={{ background: 'black' }}>

      {/* <Spinner/> */}
      {/* <Navbar/> */}
      <Navbar/>
      <Header/>
      <AboutSection/>
      <ServiceSection/>
     
      <CategoriesSection/>
      {/* <Services/> */}
      <CoursesSection/>
      <TeamSection/>
      {/* <TestimonialSection/> */}
      <FooterSection/>
      <BackToTopButton/>
    </div>
  );
};

export default App;
