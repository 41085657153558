import React from 'react';
import './header.css';

const Header = () => (
  <div className="auto__header section__margin" id="home">
    <div className="auto__header-content">
      <h1 className="gradient__text">Institute of DevOps and Project Management</h1>
     </div>

  
  </div>
);

export default Header;
