import React from 'react';
import Team1 from '../assets/img/team-1.jpg'
import Team2 from '../assets/img/anjumphoto.png'
import Team3 from '../assets/img/team-3.jpg'
import Team4 from '../assets/img/team-4.jpg'
const TeamSection = () => {
  return (
    <div className="container-xxl py-5" id='team'> 
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h1 className="mb-5">Our Team</h1>
        </div>
        <div className="row g-4">
          {/* Developer 1 */}
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="team-item bg-footer">
              <div className="overflow-hidden">
                <img className="img-fluid" src={Team1} alt="" height={"250px"}/>
              </div>
              <div className="position-relative d-flex justify-content-center" style={{ marginTop: '-23px' }}>
                <div className=" d-flex justify-content-center pt-2 px-1">
                  <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                  <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                  <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                </div>
              </div>
              <div className="text-center p-4">
                <h5 className="mb-0">Developer Name</h5>
                <small>Designation</small>
              </div>
            </div>
          </div>
          {/* Repeat similar structure for other Developers */}
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="team-item bg-footer">
              <div className="overflow-hidden">
                <img className="img-fluid" src={Team2} alt=""  style={{height:'300px',width: "100%"}}/>
              </div>
              <div className="position-relative d-flex justify-content-center" style={{ marginTop: '-23px' }}>
                <div className=" d-flex justify-content-center pt-2 px-1">
                  <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                  <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                  <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                </div>
              </div>
              <div className="text-center p-4">
                <h5 className="mb-0">Anjum Shaikh</h5>
                <small>Full Stack Developer</small>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="team-item bg-footer">
              <div className="overflow-hidden">
                <img className="img-fluid" src={Team3} alt="" />
              </div>
              <div className="position-relative d-flex justify-content-center" style={{ marginTop: '-23px' }}>
                <div className=" d-flex justify-content-center pt-2 px-1">
                  <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                  <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                  <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                </div>
              </div>
              <div className="text-center p-4">
                <h5 className="mb-0">Developer Name</h5>
                <small>Designation</small>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="team-item bg-footer">
              <div className="overflow-hidden">
                <img className="img-fluid" src={Team4} alt="" />
              </div>
              <div className="position-relative d-flex justify-content-center" style={{ marginTop: '-23px' }}>
                <div className=" d-flex justify-content-center pt-2 px-1">
                  <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                  <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                  <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                </div>
              </div>
              <div className="text-center p-4">
                <h5 className="mb-0">Developer Name</h5>
                <small>Designation</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
