import React from 'react';
import services01 from '../assets/game.jpeg';
import services02 from '../assets/Network.jpg';
import services03 from '../assets/wasm.jpg';
import services04 from '../assets/embedded.jpg';
import services05 from '../assets/terminal.webp';
import services06 from '../assets/rust.jfif';

const CoursesSection = () => {
    
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h1 className="mb-5">Our Services</h1>
        </div>
        <div className="row g-4 justify-content-center mb-4">
          {/* Course 1 */}
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="course-item" style={{ background: 'var(--color-footer)', padding:'1px'}}>
              <div className="position-relative overflow-hidden">
                <img className="img-fluid-services" src={services01} alt="" />
                 </div>
              <div className="text-center p-4 pb-0">
                <h3 className="mb-0 text-white" >Game development</h3>
                <p className="mb-4 text-white">It is the process of creating interactive digital experiences, combining design, programming, art, and sound to craft engaging games.</p>
              </div>
             
            </div>
          </div>
          {/* Course 2 */}
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="course-item " style={{ background: 'var(--color-footer)', padding:'1px' }}>
              <div className="position-relative overflow-hidden">
                <img className="img-fluid-services" src={services02} alt="" />
              </div>
              <div className="text-center p-4 pb-0">
                <h3 className="mb-0 text-white">Networking solutions</h3>
                <p className="mb-4 text-white">It involve hardware and software to establish, manage, and secure communication between devices for efficient data sharing and connectivity.</p>
              </div>
             
            </div>
          </div>
          {/* Course 3 */}
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="course-item " style={{ background: 'var(--color-footer)' , padding:'1px'}}>
              <div className="position-relative overflow-hidden">
                <img className="img-fluid-services" src={services03} alt="" />
              </div>
              <div className="text-center p-4 pb-0">
                <h3 className="mb-0 text-white">Web & wasm </h3>
                <p className="mb-4 text-white">It is a binary instruction format enabling high-performance execution in web browsers, enhancing web app capabilities significantly.</p>
              </div>
             
            </div>
          </div>
        </div>
        <div className="row g-4 justify-content-center">
          {/* Course 1 */}
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="course-item" style={{ background: 'var(--color-footer)', padding:'1px'}}>
              <div className="position-relative overflow-hidden">
                <img className="img-fluid-services" src={services04} alt="" />
                 </div>
              <div className="text-center p-4 pb-0">
                <h3 className="mb-0 text-white" >Game development</h3>
                <p className="mb-4 text-white">It is the process of creating interactive digital experiences, combining design, programming, art, and sound to craft engaging games.</p>
              </div>
             
            </div>
          </div>
          {/* Course 2 */}
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="course-item " style={{ background: 'var(--color-footer)', padding:'1px' }}>
              <div className="position-relative overflow-hidden">
                <img className="img-fluid-services" src={services05} alt="" />
              </div>
              <div className="text-center p-4 pb-0">
                <h3 className="mb-0 text-white">Networking solutions</h3>
                <p className="mb-4 text-white">It involve hardware and software to establish, manage, and secure communication between devices for efficient data sharing and connectivity.</p>
              </div>
             
            </div>
          </div>
          {/* Course 3 */}
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="course-item " style={{ background: 'var(--color-footer)' , padding:'1px'}}>
              <div className="position-relative overflow-hidden">
                <img className="img-fluid-services" src={services06} alt="" />
              </div>
              <div className="text-center p-4 pb-0">
                <h3 className="mb-0 text-white">Web & wasm </h3>
                <p className="mb-4 text-white">It is a binary instruction format enabling high-performance execution in web browsers, enhancing web app capabilities significantly.</p>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursesSection;
